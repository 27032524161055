import store, { SESSION_STORAGE_KEY } from '@/store';
import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators';

export interface HexabaseState {
  /** HexabaseのapplicationId */
  applicationId: string;
  /** HexabaseのworkspaceId */
  workspaceId: string;
  /** Hexabaseのdatastore群(アプリケーションが保持するテーブル) */
  datastoreIds: DataStore;
  /** ローディング表示 */
  isLoading: boolean;
  /** APIエラーダイアログ表示 */
  isError: boolean;
  /** APIエラーメッセージ */
  errorMessage: string;
  /** API ステータス */
  apiStatus: number;
}

interface DataStore {
  [datastoreName: string]: string;
}

/**
 * Hexabaseの操作関連の状態管理クラス
 * このクラスの状態はリロードしても状態が保持される
 */
@Module({
  name: 'hexabase',
  namespaced: true,
  dynamic: true,
  store,
  // ↓リロードしても状態を保持する場合に、この1行で追加制御が必要
  preserveState: sessionStorage.getItem(SESSION_STORAGE_KEY) !== null
})
export class Hexabase extends VuexModule implements HexabaseState {
  applicationId = '';
  workspaceId = '';
  datastoreIds: DataStore = {};
  isLoading = false;
  isError = false;
  errorMessage = '';
  apiStatus = 200;

  /**
   * HexabaseのapplicationIdを設定する
   *
   * @param applicationId HexabaseのapplicationId
   */
  @Mutation
  setApplicationId(applicationId: string) {
    this.applicationId = applicationId;
  }

  /**
   * HexabaseのworkspaceIdを設定する
   *
   * @param workspaceId HexabaseのworkspaceId
   */
  @Mutation
  setWorkspaceId(workspaceId: string) {
    this.workspaceId = workspaceId;
  }

  /**
   * HexabaseのdatastoreIdsを設定する
   *
   * @param datastoreIds HexabaseのdatastoreIds
   */
  @Mutation
  setDatastoreIds(datastoreIds: DataStore) {
    this.datastoreIds = datastoreIds;
  }

  /**
   * ローディングを表示する
   *
   * @param isLoading ローディング表示可否
   */
  @Mutation
  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }

  /**
   * ローディング表示可否を取得する
   *
   * @returns ローディング表示可否
   */
  public get getIsLoading(): boolean {
    return this.isLoading;
  }

  /**
   * エラーダイアログを表示する
   *
   * @param isError エラーダイアログ表示可否
   */
  @Mutation
  setIsError(isError: boolean) {
    this.isError = isError;
  }

  /**
   * エラーダイアログ表示可否を取得する
   *
   * @returns エラーダイアログ表示可否
   */
  public get getIsError(): boolean {
    return this.isError;
  }

  /**
   * エラーメッセージを表示する
   *
   * @param errorMessage エラーメッセージ
   */
  @Mutation
  setErrorMessage(errorMessage: string) {
    this.errorMessage = errorMessage;
  }

  /**
   * エラーメッセージを取得する
   *
   * @returns エラーメッセージ
   */
  public get getErrorMessage(): string {
    return this.errorMessage;
  }

  /**
   * 直近APIステータスを表示する
   *
   * @param apiStatus status code
   */
  @Mutation
  setApiStatus(apiStatus: number) {
    this.apiStatus = apiStatus;
  }

  /**
   * 直近APIステータスを取得する
   *
   * @returns 直近APIステータス
   */
  public get getApiStatus(): number {
    return this.apiStatus;
  }

  /**
   * HexabaseStateをリセットする
   */
  @Mutation
  clearHexabaseState() {
    this.applicationId = '';
    this.workspaceId = '';
    this.datastoreIds = {};
    this.isLoading = false;
    this.isError = false;
    this.errorMessage = '';
    this.apiStatus = 200;
  }
}

export default getModule(Hexabase);

import store, { SESSION_STORAGE_KEY } from '@/store';
import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators';

export interface SearchState {
  /** ページをまたぐ検索条件 */
  searchCondition: Array<SearchCondition>;
}

interface SearchCondition {
  [key: string]: any;
}

/**
 * ページ間の共有が必要な検索条件の状態管理クラス
 * このクラスの状態はリロードすると状態がリセットされる
 */
@Module({
  name: 'search',
  namespaced: true,
  dynamic: true,
  store,
  preserveState: false
})
export class Search extends VuexModule implements SearchState {
  searchCondition: Array<SearchCondition> = [];

  /**
   * 検索条件を設定する
   *
   * @param conditions 格納検索条件
   */
  @Mutation
  setConditions(conditions: Array<{ [k: string]: any }>) {
    this.searchCondition = conditions;
  }

  /**
   * HexabaseStateをリセットする
   */
  @Mutation
  clearsearchCondition() {
    this.searchCondition.splice(0);
  }
}

export default getModule(Search);

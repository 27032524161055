



















































import { Component, Vue, Prop, Emit } from 'vue-property-decorator';
import SingleText from '@/components/common/elements/SingleText.vue';
import Typography from '@/components/common/elements/Typography.vue';
import Table from '@/components/common/elements/Table.vue';

@Component({
  components: { SingleText, Typography, Table }
})
export default class Dialog extends Vue {
  // ダイアログ開閉状態
  @Prop({ default: false })
  private dialog?: boolean;
  // ダイアログ内表示タイトル
  @Prop({ default: '' })
  private title!: string;
  // ダイアログ内表示テキスト
  @Prop({ default: '' })
  private bodyText!: string;
  // ダイアログのエリア外を押下した際にBOXを閉じるか
  @Prop({ default: true })
  private isPersistent?: boolean;
  // ダイアログ内ボタンをテキストに変更
  @Prop({ default: false })
  private isTextActionButton?: boolean;
  // ダイアログ内NGボタン文字
  @Prop({ default: 'キャンセル' })
  private buttonTextNG?: string;
  // ダイアログ内OKボタン文字
  @Prop({ default: 'OK' })
  private buttonTextOK?: string;
  // ダイアログ内NGボタン文字色
  @Prop({ default: 'light' })
  private buttonColorNG?: string;
  // ダイアログ内OKボタン文字色
  @Prop({ default: 'primary' })
  private buttonColorOK?: string;
  // ダイアログの幅
  @Prop({ default: '1500'})
  private maxWidth?: string;

  // OKボタンを表示するか
  @Prop({ default: true })
  private isDisplayOk?: boolean;
  // キャンセルボタンを表示するか
  @Prop({ default: true })
  private isDisplayCancel?: boolean;
  // テーブルに表示するカラム配列
  @Prop({ default: () => [] })
  private columns?: Array<{ [k: string]: string | number }>;
  // テーブルに表示するデータ配列
  @Prop({ default: () => [] })
  private body?: Array<{ [k: string]: string | number }>;

  /**
   * 親コンポーネントに押下したか否か通知する
   * @module addDetailsOpratipon
   * @return {AddDetailResponse} - 要素ステータス、押下判定
   */
  @Emit('confirmOpratipon')
  public confirmOpratipon(isAgree: boolean): { [k: string]: boolean } {
    return { dialog: false, isAgree: isAgree };
  }
}

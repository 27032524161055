import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Auth from '@/store/auth';
import SearchState from "@/store/search";
import { NotFoundMessage } from '@/constants/util';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/signin',
    name: 'Signin',
    component: () => import('../views/Signin.vue')
  },
  {
    path: '/signin/forget',
    name: 'Forget',
    component: () => import('../views/Forget.vue')
  },
  {
    path: '/signin/reset_password/:token',
    name: 'ResetPassword',
    component: () => import('../views/ResetPassword.vue')
  },
  {
    path: '/doc_register',
    name: 'Doc_register',
    component: () => import('../domains/documentManagement/views/DocumentRegister.vue'),
    meta: {
      title: '文書登録',
      breadcrumbs: [
        {
          name: '文書管理'
        },
        {
          name: '文書登録'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/doc_list',
    alias: '/',
    name: 'Doc_list',
    component: () => import('../domains/documentManagement/views/DocumentList.vue'),
    meta: {
      title: '文書一覧',
      breadcrumbs: [
        {
          name: '文書管理'
        },
        {
          name: '文書一覧'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/doc_edit/:id',
    name: 'Doc_edit',
    component: () => import('../domains/documentManagement/views/DocumentEdit.vue'),
    meta: {
      title: '文書詳細',
      breadcrumbs: [
        {
          name: '文書管理'
        },
        {
          name: '文書詳細'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/doc_csv_output',
    name: 'Doc_csv_output',
    component: () => import('../domains/documentManagement/views/DocumentCsvOutput.vue'),
    meta: {
      title: '文書CSV出力',
      breadcrumbs: [
        {
          name: '文書管理'
        },
        {
          name: '文書CSV出力'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/doc_category_register',
    name: 'Doc_category_register',
    component: () => import('../domains/documentManagement/views/DocumentCategoryRegister.vue'),
    meta: {
      title: 'カテゴリ登録',
      breadcrumbs: [
        {
          name: '文書管理'
        },
        {
          name: 'カテゴリ登録'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/doc_category_list',
    name: 'Doc_category_list',
    component: () => import('../domains/documentManagement/views/DocumentCategoryList.vue'),
    meta: {
      title: 'カテゴリ一覧',
      breadcrumbs: [
        {
          name: '文書管理'
        },
        {
          name: 'カテゴリ一覧'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/doc_category_edit/:id',
    name: 'Doc_category_edit',
    component: () => import('../domains/documentManagement/views/DocumentCategoryEdit.vue'),
    meta: {
      title: 'カテゴリ詳細',
      breadcrumbs: [
        {
          name: '文書管理'
        },
        {
          name: 'カテゴリ詳細'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/setting/employee/list',
    name: 'SettingEmployeeList',
    component: () => import('../views/SettingEmployeeList.vue'),
    meta: {
      title: '社員マスタ検索',
      breadcrumbs: [
        {
          name: '設定'
        },
        {
          name: '社員マスタ検索'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/setting/employee/register',
    name: 'SettingEmployeeRegister',
    component: () => import('../views/SettingEmployeeRegister.vue'),
    meta: {
      title: '社員マスタ登録',
      breadcrumbs: [
        {
          name: '社員マスタ検索'
        },
        {
          name: '社員マスタ登録'
        }
      ],
      requiresAuth: true
    }
  },
  {
    path: '/setting/employee/edit/:id',
    name: 'SettingEmployeeEdit',
    component: () => import('../views/SettingEmployeeEdit.vue'),
    meta: {
      title: '社員マスタ詳細',
      breadcrumbs: [
        {
          name: '社員マスタ検索'
        },
        {
          name: '社員マスタ詳細'
        }
      ],
      requiresAuth: true
    }
  },
  // ↓ ルートが見つからない場合のPageNotFound ↓
  {
    path: '*',
    name: 'NotFound',
    component: () => import('../views/NotFound.vue'),
    meta: {
      title: NotFoundMessage
    }
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  }
});

// ログイン状態を確認しログインしていない場合サインイン画面へリダイレクト
router.beforeEach((to, _, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // queryでsearchCondtions: trueを指定していない場合は保持している検索条件をクリアする
    if (SearchState.searchCondition.length > 0 && !to.query.searchConditions) {
      SearchState.clearsearchCondition();
    }
    if (!Auth.isLogin) {
      next({ path: '/signin', query: { redirect: to.fullPath } });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

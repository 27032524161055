






import hexabaseState from '@/store/hexabase';
export default {
  computed: {
    isLoading() {
      return hexabaseState.getIsLoading;
    }
  }
};

/*
 * 共通エラーコンポーネント用設定ファイル
 *   更新履歴
 *   2021/07/27 新規
 */

// ダイアログタイトル
export const dialogTitle = 'システムエラーが発生しました';

// APIセッション切れステータス
export const apiOutSessionStatus = 401;

// ネットワークエラーステータス
export const networkErrorStatus = 0;

// ダイヤログ内メッセージ（管理マスタkey）
export const sessionMessageKey = 'RE_LOGIN_MESSAGE';

// セッション切れデフォルトメッセージ
export const defaultSessionErrMessage =
  'ログイン有効期限が切れました。\n再度ログインしてください。';

// 遷移用ボタン設定
export const buttonConfig = {
  portal: {
    route: 'Portal', // 遷移先名
    label: 'ポータルへ' // ボタンラベル名
  },
  signin: {
    route: 'Signin',
    label: 'ログアウト'
  },
  supplierPortal: {
    route: 'SupplierPortal',
    label: 'ポータルへ'
  },
  close: {
    route: '', // closeなので遷移先はなし
    label: '閉じる'
  }
};

import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './models/validation/validation';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import '@mdi/font/css/materialdesignicons.css';

require('@/assets/scss/main.scss');

Vue.use(Vuetify);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify: new Vuetify(),
  render: h => h(App)
}).$mount('#app');

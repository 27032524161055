// import { PurchaseCheckApplicantFieldId } from '@/constants/purchase/purchase';
// import { OrderCheckApplicantFieldId } from '@/constants/sample/sample';

export const listPerPage = 10;

export const statusLabel: { [k: string]: string } = {
  making: '作成中',
  requesting: '依頼中',
  approved: '承認済',
  confirm: '確定',
  obtained: '入手済'
};

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace errorDefine {
  // 各例外処理のエラーコード・エラーメッセージを定義
  export const config: Array<{ errType: string; code: string; message: string }> = [
    { errType: 'type', code: '001', message: 'The type is different' },
    { errType: 'arg', code: '010', message: 'Not enough arguments' },
    { errType: 'master', code: '100', message: '社員登録を確認してください。' }
  ];
}
export const errorConf = errorDefine.config;

interface ButtonDefine {
  [k: string]: {
    [k: string]: {
      disabledRule: { [k: string]: string };
    };
  };
}

/**
 * アイテム詳細画面にて非活性にするボタン
 * 'order_applied_approve': { // 対象となるステータスID
 *    disabledRule: {
 *      rule: 'userItemId', // 非活性にする条件
 *      fieldId: limitedOrderDisplayId // 条件と付け合わせるフィールド
 *    }
 *  }
 * 非活性にする条件
 * userItemId: 特定フィールドにログインユーザのID（社員マスタアイテムID）が存在する際非活性
 * initial: 無条件に非活性にする
 *
 */
// const limitedOrderDisplayId = OrderCheckApplicantFieldId;
// const limitedPurchaseDisplayId = PurchaseCheckApplicantFieldId;
// export const buttonDefine: ButtonDefine = {
//   'order': {
//     'order_applied_approve': {
//       disabledRule: {
//         rule: 'userItemId',
//         fieldId: limitedOrderDisplayId
//       } //現場承認
//     },
//     'order_approved_decide': {
//       disabledRule: {
//         rule: 'userItemId',
//         fieldId: limitedOrderDisplayId
//       } //発注決裁する
//     },
//     'order_decided_send_mail': {
//       disabledRule: {
//         rule: 'userItemId',
//         fieldId: limitedOrderDisplayId
//       } //発注する（メール送信あり）
//     },
//     'order_decided_send_fax': {
//       disabledRule: {
//         rule: 'userItemId',
//         fieldId: limitedOrderDisplayId
//       } //発注する（fax送信あり）
//     },
//     'order_decided_send_no_mail': {
//       disabledRule: {
//         rule: 'userItemId',
//         fieldId: limitedOrderDisplayId
//       } //発注する（メール送信なし）
//     },
//     'order_sent_reissue_mail': {
//       disabledRule: {
//         rule: 'userItemId',
//         fieldId: limitedOrderDisplayId
//       } //再発行する（メール送信あり）
//     },
//     'order_sent_reissue_fax': {
//       disabledRule: {
//         rule: 'userItemId',
//         fieldId: limitedOrderDisplayId
//       } //再発注する（メール送信あり）
//     },
//     'order_sent_reissue_no_mail': {
//       disabledRule: {
//         rule: 'userItemId',
//         fieldId: limitedOrderDisplayId
//       } //再発行する（メール送信なし）
//     }
//   },
//   'purchase': {
//     'order_purchase_estimate_request_applied_approve': {
//       disabledRule: {
//         rule: 'userItemId',
//         fieldId: limitedPurchaseDisplayId
//       } //購買見積依頼承認する
//     },
//     'order_purchase_order_request_applied_approve': {
//       disabledRule: {
//         rule: 'userItemId',
//         fieldId: limitedPurchaseDisplayId
//       } //購買発注依頼承認する
//     }
//   }
// }

// NotFoundページに表示する文言
export const NotFoundMessage = 'お探しのページは見つかりませんでした';

export interface Buttons {
  id: number;
  color?: string;
  type: string;
  name: string;
  value: string;
  route?: string;
  routeName?: string;
  setting?: { [k: string]: { [k: string]: string } };
}










import Loading from '@/components/common/Loading.vue';
import DialogError from '@/components/common/DialogError.vue';
import { Route } from 'vue-router';
import { META } from '@/constants/meta';

export default {
  name: 'App',
  components: {
    Loading,
    DialogError
  },
  methods: {
    setMeta(route: Route): void {
      // タイトルを設定
      const setTitle = route.meta?.title
        ? META.HEAD.TITLE + ' - ' + route.meta.title
        : META.HEAD.TITLE;
      document.title = setTitle;
      // ディスクリプションを設定
      const setDesc = route.meta?.desc ? route.meta.desc : META.HEAD.DESCRIPTION;
      document.querySelector("meta[name='description']")?.setAttribute('content', setDesc);
    }
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    const route = this.$route;
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    this.setMeta(route);
  },
  watch: {
    $route(route: Route) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      this.setMeta(route);
    }
  }
};

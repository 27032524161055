














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class SingleText extends Vue {
  // 表示させるテキスト
  @Prop({ default: '' })
  private str!: string;
  // 追加付与するクラス
  @Prop({ default: '' })
  private addClass?: string;
}

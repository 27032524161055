/**
 * VeeValidateによるバリデーション定義
 */
/* eslint-disable @typescript-eslint/camelcase */
import { DateTime } from 'luxon';
import Vue from 'vue';
import { ValidationProvider, ValidationObserver, extend, localize } from 'vee-validate';
import ja from 'vee-validate/dist/locale/ja.json';
import {
  required,
  email,
  confirmed,
  numeric,
  max,
  max_value,
  min_value,
  between,
  integer
} from 'vee-validate/dist/rules';

extend('required', required);
extend('email', email);
extend('confirmed', confirmed);
extend('numeric', numeric);
extend('max', {
  ...max,
  message: '{length} 文字以内で入力してください'
});
extend('max_value', max_value);
extend('min_value', min_value);
extend('integer', integer);

/* 納品処理モーダル内テーブルで使用する入力チェック */
extend('max_value_delivery_quantity', {
  ...max_value,
  message: '納品数の入力値に誤りがあります'
});
extend('min_value_delivery_quantity', {
  ...min_value,
  message: '納品数の入力値に誤りがあります'
});
extend('delivery_edit_quantity_required', {
  ...required,
  message: '納品修正数は入力必須です'
});
extend('between_delivery_edit_quantity', {
  ...between,
  message: '納品修正数は指定範囲内で入力ください'
});

/* 納品処理モーダル内テーブルで使用する入力チェック */
extend('check_delivery_location_id', {
  ...required,
  message: '納入場所が一致していないので選択してください'
});
/* 検収処理モーダル内テーブルで使用する入力チェック */
extend('max_value_acceptance_quantity', {
  ...max_value,
  message: '検収数の入力値に誤りがあります'
});
extend('min_value_acceptance_quantity', {
  ...min_value,
  message: '検収数の入力値に誤りがあります'
});
extend('between_acceptance_quantity', {
  ...between,
  message: '検収数は指定範囲内で入力ください'
});

extend('chk_after_the_day', {
  message: '当日以降の日付を入力してください',
  validate(value) {
    const today = DateTime.fromISO(DateTime.local().toFormat('yyyy-MM-dd'));
    const inputDay = DateTime.fromISO(value);
    return inputDay >= today;
  }
});

extend('chk_min_date', {
  validate(value, params: any) {
    const targetDay = DateTime.fromISO(value); //入力値
    if (params.length > 0) {
      const minDay = DateTime.fromISO(DateTime.fromISO(String(params[0])).toFormat('yyyy-MM-dd'));
      return targetDay >= minDay ? true : `${params[1]}以降の日付を入力してください`;
    } else {
      return true;
    }
  }
});

extend('chk_max_date', {
  validate(value, params: any) {
    const targetDay = DateTime.fromISO(value); //入力値
    if (params.length > 0) {
      const maxDay = DateTime.fromISO(DateTime.fromISO(String(params[0])).toFormat('yyyy-MM-dd'));
      return targetDay <= maxDay ? true : `${params[1]}以前の日付を入力してください`;
    } else {
      return true;
    }
  }
});

extend('selectRequired', {
  message: '{_field_}は必須項目です',
  ...required,
  validate(value) {
    const val = typeof value === 'object' ? value.value : value;
    if (val) {
      return true;
    }
    return false;
  }
});

extend('multiSelectRequired', {
  message: '{_field_}は必須項目です',
  ...required,
  validate(value) {
    return Array.isArray(value) && value.length > 0 ? true : false;
  }
});

extend('integer_greater_than_zero', {
  message: '{_field_}は0より大きい整数で入力してください',
  ...required,
  validate(value) {
    const val = Number(value);
    if (val > 0 && Number.isInteger(val)) {
      return true;
    }
    return false;
  }
});

/**
 * アップロードファイルサイズチェック
 * 初期値はtrueを返却
 */
extend('fileMaxSize', {
  ...required,
  validate(value: File | File[], params: any) {
    const maxmum = Number(Object.values(params)[0]);
    if (!Number.isInteger(maxmum)) return true;

    const errMessage = 'ファイルサイズを確認してください ';

    const checkSize = (name: string, size: number, max: number): string | null => {
      return size >= maxmum ? name : null;
    };

    if (Array.isArray(value)) {
      const ngFileNames: string[] = [];
      for (const i in value) {
        const check = checkSize(value[i].name, value[i].size, maxmum);
        if (check !== null) ngFileNames.push(check);
      }
      return ngFileNames.length === 0 ? true : `${errMessage}(${ngFileNames.join('、')})`;
    }

    const check = checkSize(value.name, value.size, maxmum);
    return check === null ? true : `${errMessage}(${check})`;
  }
});

/**
 * アップロードファイル数チェック
 * 初期値はtrueを返却
 * paramsには現状アップ数を動的差し引いた数を付与
 */
extend('fileMaxCount', {
  ...required,
  validate(value: File | File[], params: any) {
    const upLoadLimit = Number(Object.values(params)[0]);
    if (!Number.isInteger(upLoadLimit)) return true;

    const errMessage = `アップロード可能ファイル数の上限を超えています(${upLoadLimit}まで選択可能)`;

    const upLoadFileList: File[] = Array.isArray(value) ? [...value] : [value];
    return upLoadFileList.length > upLoadLimit ? errMessage : true;
  }
});

/**
 * アップロードファイルの拡張子チェック
 * 初期値はtrueを返却
 */
extend('fileAllowExt', {
  ...required,
  validate(value: File | File[], params: any) {
    if (Object.values(params).length === 0) {
      return true;
    }

    const errMessage = 'ファイルの種類を確認してください ';
    const allowedExts = Object.values(params)[0] as string[];

    const checkExt = (name: string, allowList: string[]) => {
      const ext = name.split('.');
      if (ext[1] === undefined || ext[1].length === 0) return name;
      const check = allowList.some(value => ext[1] === value);
      return check ? null : name;
    };

    if (Array.isArray(value)) {
      const ngFileNames: string[] = [];
      for (const i in value) {
        const check = checkExt(value[i].name, allowedExts);
        if (check !== null) ngFileNames.push(check);
      }
      return ngFileNames.length === 0 ? true : `${errMessage}(${ngFileNames.join('、')})`;
    }

    const getVaild = checkExt(value.name, allowedExts);
    return getVaild === null ? true : `${errMessage}(${getVaild})`;
  }
});

/**
 * アップロードファイルの拡張子チェック
 * 初期値はtrueを返却
 */
extend('fileAllowType', {
  ...required,
  validate(value: File | File[], params: any) {
    if (Object.values(params).length === 0) {
      return true;
    }

    const errMessage = 'ファイルの種類を確認してください ';
    const allowedExts = Object.values(params)[0] as string[];

    const checkType = (name: string, type: string, allowList: string[]) => {
      const check = allowList.every(value => type.indexOf(value) === -1);
      return check ? name : null;
    };

    if (Array.isArray(value)) {
      const ngFileNames: string[] = [];
      for (const i in value) {
        const check = checkType(value[i].name, value[i].type, allowedExts);
        if (check !== null) ngFileNames.push(check);
      }
      return ngFileNames.length === 0 ? true : `${errMessage}(${ngFileNames.join('、')})`;
    }

    const getVaild = checkType(value.name, value.type, allowedExts);
    return getVaild === null ? true : `${errMessage}(${getVaild})`;
  }
});

/**
 * アップロードファイル数チェック
 * 初期値はtrueを返却
 */
extend('fileRequired', {
  ...required,
  validate(value: any, params: any) {
    const errMessage = 'ファイルを添付してください';
    const fileCount = value;
    if (!Number.isInteger(fileCount)) return true;
    return fileCount === 0 ? errMessage : true;
  }
});

/**
 * 書式チェック電話番号他
 * 半角数字またはハイフンのみを許可
 **/
extend('wrong_format_for_number_and_hyphen', {
  message: '{_field_}は数字かハイフンのみ許可されています',
  ...required,
  validate(value) {
    // 空も許可する
    if (value === undefined || value === '') {
      return true;
    }
    const val = /^[0-9-]+$/.test(value);
    if (val) {
      return true;
    }
    return false;
  }
});

extend('between_quantity', {
  ...between,
  message: '指定範囲内で入力ください'
});
extend('max_value_quantity', {
  ...max_value,
  message: '入力値に誤りがあります'
});
extend('min_value_quantity', {
  ...min_value,
  message: '入力値に誤りがあります'
});

extend('between_price', {
  ...between,
  message: '指定範囲内で入力ください'
});
extend('max_value_price', {
  ...max_value,
  message: '入力値に誤りがあります'
});
extend('min_value_price', {
  ...min_value,
  message: '入力値に誤りがあります'
});

extend('greater_than_or_equal_to_zero', {
  message: '{_field_}は0以上の整数で入力してください',
  ...required,
  validate(value) {
    const val = Number(value);
    if (val >= 0 && Number.isInteger(val)) {
      return true;
    }
    return false;
  }
});

localize('ja', ja);

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

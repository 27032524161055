import store, { SESSION_STORAGE_KEY } from '@/store';
import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators';

export interface AuthState {
  /** Hexabaseのtoken */
  token: string;
}

/**
 * Hexabase認証の状態管理クラス
 * このクラスの状態はリロードしても状態が保持される
 */
@Module({
  name: 'auth',
  namespaced: true,
  dynamic: true,
  store,
  // ↓リロードしても状態を保持する場合に、この1行で追加制御が必要
  preserveState: sessionStorage.getItem(SESSION_STORAGE_KEY) !== null
})
export class Auth extends VuexModule implements AuthState {
  token = '';

  /**
   * Hexabaseのtokenを設定する
   *
   * @param token Hexabaseのtoken
   */
  @Mutation
  setToken(token: string) {
    this.token = token;
  }

  /**
   * Hexabaseのtokenをリセットする
   */
  @Mutation
  clearToken() {
    this.token = '';
  }

  /**
   * Hexabaseにログインしているかどうかを返す
   *
   * @returns true: ログインしている/false: ログインしていない
   */
  public get isLogin(): boolean {
    return this.token != '';
  }
}

export default getModule(Auth);

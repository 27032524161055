











































































































































































































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { TableColumn, PropsDefine, imageItem } from '@/constants/components/table';

@Component({})
export default class Table extends Vue {
  // テーブルに表示するカラム配列
  @Prop({ default: () => [] })
  private columns?: Array<TableColumn>;
  // テーブルに表示するデータ配列
  @Prop({ default: () => [] })
  private body?: Array<{ [k: string]: string }>;
  // 表示アイテム数
  @Prop({ default: 100 })
  private perPage?: number;
  // チェックボックス項目の表示制御
  @Prop({ default: false })
  private showSelect?: boolean;
  @Prop({ default: '' })
  private itemKey?: string;
  // 選択されているチェックボックス
  private selected = [];
  // テーブル内項目のchip制御
  @Prop({ default: () => [] })
  private chipItems?: Array<{ [k: string]: string }>;
  @Prop({ default: () => [] })
  private buttonItems?: Array<{ [k: string]: string }>;
  @Prop({ default: () => [] })
  private inputItems?: Array<{ [k: string]: string }>;
  @Prop({ default: () => [] })
  private imageItems?: Array<imageItem>;

  @Prop({ default: '' })
  private addClass?: string;

  @Prop()
  private customFilter?: Function;

  @Prop()
  private customSort?: Function;

  // テーブル内テキスト
  @Prop({ default: '' })
  private tableText?: string;

  @Prop({ default: false })
  private hideDefaultHeader?: boolean;

  private errors: { [k: string]: string } = {};

  @Watch('indexedItems')
  private clearSelected() {
    this.selected.splice(0);
  }

  getBase(define: PropsDefine, item: any) {
    const isPositive =
      Math.sign(item.order_quantity) > -1 || Math.sign(item.delivery_quantity) > -1 || false;
    const self =
      define.self !== undefined && this.chkValue(item[define.self]) ? Number(item[define.self]) : 0;
    const max =
      define.maxKey !== undefined && this.chkValue(item[define.maxKey])
        ? Number(item[define.maxKey])
        : 0;
    const min =
      define.minKey !== undefined && this.chkValue(item[define.minKey])
        ? Number(item[define.minKey])
        : 0;
    let sum = 0;
    if (
      define.sumKey !== undefined &&
      item[define.sumKey] !== undefined &&
      item[define.sumKey].length > 0
    ) {
      const castArray = (item[define.sumKey] as string[]).map(val => {
        return Number(val);
      });
      sum = castArray.reduce((a, b) => {
        return a + b;
      });
    }
    return { isPositive: isPositive, self: self, max: max, min: min, sum: sum };
  }

  /**
   * 上限入力許容値を取得
   * @param {PropsDefine} define - カラム設定
   * @param {any} item - レコード情報
   * @returns maxカウント数
   */
  getMax(define: PropsDefine, item: any) {
    const rules = define.rules;
    if (rules === undefined) return null;

    const baseData = this.getBase(define, item);
    if (
      rules.includes('max_value_delivery_quantity') ||
      rules.includes('max_value_acceptance_quantity')
    ) {
      return baseData.self + baseData.max;
    } else if (
      rules.includes('between_delivery_edit_quantity') ||
      rules.includes('between_acceptance_quantity')
    ) {
      return baseData.isPositive ? baseData.self + baseData.max : baseData.sum;
    }
    return define.maxKey !== undefined
      ? define.maxKey
      : define.max !== undefined
      ? define.max
      : null;
  }

  /**
   * 下限入力許容値を取得
   * @param {PropsDefine} define - カラム設定
   * @param {any} item - レコード情報
   * @returns minカウント数
   */
  getMin(define: PropsDefine, item: any) {
    const rules = define.rules;
    if (rules === undefined) return null;

    const baseData = this.getBase(define, item);
    if (
      rules.includes('between_delivery_edit_quantity') ||
      rules.includes('between_acceptance_quantity')
    ) {
      return baseData.isPositive ? baseData.sum : baseData.max + baseData.self;
    }
    return define.minKey !== undefined
      ? item[define.minKey]
      : define.min !== undefined
      ? define.min
      : null;
  }

  /**
   * 特定設定値値が存在する場合バリデーションルールに追加します
   * @param {PropsDefine} define - カラム設定
   * @param {any} item - レコード情報
   * @returns バリデーションルール
   */
  private modifyRule(define: PropsDefine, item: any) {
    const result: string[] = [];
    const rules = define.rules;
    if (rules === undefined || rules.length === 0) return '';

    const baseData = this.getBase(define, item);
    rules.forEach(rule => {
      switch (rule) {
        case 'between_delivery_edit_quantity': {
          if (baseData.isPositive) {
            result.push(`max_value_delivery_quantity:${baseData.self + baseData.max}`);
            result.push(`min_value_delivery_quantity:${baseData.sum}`);
          } else {
            result.push(`max_value_delivery_quantity:${baseData.sum}`);
            result.push(`min_value_delivery_quantity:${baseData.self + baseData.max}`);
          }
          break;
        }
        case 'between_acceptance_quantity': {
          if (baseData.isPositive) {
            result.push(`max_value_acceptance_quantity:${baseData.self + baseData.max}`);
            result.push(`min_value_acceptance_quantity:${baseData.sum}`);
          } else {
            result.push(`max_value_acceptance_quantity:${baseData.sum}`);
            result.push(`min_value_acceptance_quantity:${baseData.self + baseData.max}`);
          }
          break;
        }
        case 'max_value_delivery_quantity': {
          result.push(`max_value_delivery_quantity:${baseData.self + baseData.max}`);
          break;
        }
        case 'max_value_acceptance_quantity': {
          result.push(`max_value_acceptance_quantity:${baseData.self + baseData.max}`);
          break;
        }
        case 'delivery_edit_quantity_required': {
          result.push(`delivery_edit_quantity_required`);
          break;
        }
        case 'required': {
          result.push(`required`);
          break;
        }
        case 'between_quantity': {
          // 正数・負数のチェック
          if (Math.sign(item[define.name]) > -1 || false) {
            result.push(`max_value_quantity:${define.max}`);
          } else {
            result.push(`min_value_quantity:${define.min}`);
          }
          break;
        }
        case 'between_price': {
          // 正数・負数のチェック
          if (Math.sign(item[define.name]) > -1 || false) {
            result.push(`max_value_price:${define.max}`);
          } else {
            result.push(`min_value_price:${define.min}`);
          }
          break;
        }
        default: {
          break;
        }
      }
    });
    return result.join('|');
  }

  /**
   * v-for keyをレコード情報を使って動的に生成する
   * @param {PropsDefine} define - カラム設定
   * @param {any} item - レコード情報
   * @returns key
   */
  private generateKey(define: PropsDefine, item: any) {
    return `${define.name}-${item.id}`;
  }

  /**
   * 該当カラム名（日本語）を返却
   * @param {string} name - カラムvalue（英名）
   * @returns {string|null}
   */
  private getColumnName(name: string): string | null {
    if (this.columns === undefined) return '';
    const column = this.columns.find(column => column.value === name);
    return column !== undefined ? column.text : null;
  }

  /**
   * 親コンポーネントに押下した行を通知する
   * @module clickRowOperation
   * @param  { [k: string]: string } - 対象行データ
   * @return { [k: string]: string } - 押下された行データ
   */
  @Emit('clickRowOperation')
  public clickRowOperation(clickRecord: { [k: string]: string }): { [k: string]: string } {
    return clickRecord;
  }

  /**
   * 親コンポーネントにチェックボックスをチェック、アンチェックした行を通知する
   * valueにはチェックした場合true, アンチェックした場合はfalseが与えられる
   * itemは定義されているテーブル項目によって可変となる
   * @module itemSelected
   * @param {item: any, value: boolean}
   * @return {item: any, value: boolean}
   */
  @Emit('itemSelected')
  public itemSelected(value: { item: any; value: boolean }) {
    return value;
  }

  /**
   * 親コンポーネントに選択されているアイテムを配列で返します
   * @module enterSelect
   * @param Array<{[k: string]: string }>
   * @return Array<{[k: string]: string }>
   */
  @Emit('enterSelect')
  private enterSelect(dataList: Array<{ [k: string]: string }>) {
    return dataList;
  }

  /**
   * 親コンポーネントにボタンが押された行のアイテムをオブジェクトで返します
   * @module clickButton
   * @param record - アイテム情報
   * @param buttonName - ボタン名
   * @return { record, buttonName, itemIndex }
   */
  @Emit('clickButton')
  clickButton(record: any, buttonName: string) {
    const itemIndex = this.indexedItems.indexOf(record);
    return { record, buttonName, itemIndex };
  }

  /**
   * 親コンポーネントにテキストフィールドで入力された行のアイテムをオブジェクトで返します
   * @module getData
   * @param inputValue - 入力値
   * @param inputName - 入力フィールド名
   * @param record - アイテム情報
   * @return { inputValue, inputName, itemIndex, record }
   */
  @Emit('getData')
  getdata(inputValue: number, props: { [k: string]: string | number }, record: any) {
    const itemIndex = this.indexedItems.indexOf(record);
    const inputName = props.name as string;
    return { inputValue, inputName, itemIndex, record };
  }

  @Emit('checkErr')
  checkErr(errors: string[], record: any): { [k: string]: string } {
    if (errors.length > 0) {
      this.errors[record.id] = errors[0];
    } else {
      if (record.id in this.errors) {
        delete this.errors[record.id];
      }
    }
    return this.errors;
  }

  // v-slotの動的な生成に使用
  private addHeader(value: string) {
    return `item.` + value;
  }

  get indexedItems() {
    if (this.body === undefined) return [];
    return this.body.map((item, index) => ({
      id: index,
      ...item
    }));
  }

  chkValue(str: string | number | undefined) {
    return str !== undefined && str !== null;
  }

  /**
   * レコード内単位でinput要素のreadOnly属性チェック
   * @module inputFlagCheck
   * @param item - 設定するアイテムのレコード情報
   * @param inputItem - レコードのフォーマット情報
   * @return readOnlyパラメータを使用するか？ true：readOnly→ON、false：readOnly→OFF
   */
  private inputFlagCheck(item: any, inputItem: any): any {
    if (item[inputItem.inputCheckName] === false) {
      return true;
    }
    return false;
  }

  /**
   * 画像ソースを取得
   */
  getImageSrc(item: { [k: string]: string }, image: imageItem) {
    let imageSrc;

    if (image.isRegex) {
      imageSrc = image.imageList.find(i => item[image.name].match(i.key));
    } else {
      imageSrc = image.imageList.find(i => i.key === item[image.name]);
    }

    if (!imageSrc) {
      imageSrc = image.defaultSrc;
    }
    return imageSrc;
  }

  /**
   * テーブル内ボタンラベルにしいていしたものを表示するか
   * データ内の文字列を表示するか切り替え
   */
  getButtonLabel(
    item: { [k: string]: string },
    button: { [k: string]: string | number | boolean }
  ) {
    const target: string = button.name as string;
    if (button.recordText !== undefined && button.recordText && target in item) {
      return item[target] !== null && item[target].length > 0 ? item[target] : button.text;
    }
    return button.text;
  }
}

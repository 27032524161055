



















import { Component, Vue, Emit } from 'vue-property-decorator';
import hexabaseState from '@/store/hexabase';
import appState from '@/store/app';

import {
  dialogTitle,
  sessionMessageKey,
  defaultSessionErrMessage,
  buttonConfig,
  apiOutSessionStatus,
  networkErrorStatus
} from '@/constants/components/dialogError';

import SingleText from '@/components/common/elements/SingleText.vue';
import Typography from '@/components/common/elements/Typography.vue';
import Table from '@/components/common/elements/Table.vue';
import Dialog from '@/components/common/modules/Dialog.vue';

@Component({
  components: { SingleText, Typography, Table, Dialog }
})
export default class DialogError extends Vue {
  private dialogTitle = dialogTitle;

  private sessionMessageKey = sessionMessageKey;

  private isError() {
    return hexabaseState.isError;
  }

  private getMessage() {
    let message = hexabaseState.errorMessage;

    if (hexabaseState.apiStatus === apiOutSessionStatus) {
      message = this.getSessionErrMessage();
    }

    return message;
  }

  private getSessionErrMessage(): string {
    if (
      appState.options !== undefined &&
      appState.options['common'] &&
      appState.options['common'][this.sessionMessageKey]
    ) {
      return appState.options['common'][this.sessionMessageKey];
    }
    return defaultSessionErrMessage;
  }

  private getButtonText() {
    if (hexabaseState.apiStatus === 0) {
      return buttonConfig.close.label;
    } else {
      return hexabaseState.apiStatus === apiOutSessionStatus
        ? buttonConfig.signin.label
        : buttonConfig.portal.label;
    }
  }

  /**
   * 親コンポーネントに押下したか否か通知する
   * @module addDetailsOpratipon
   * @return {AddDetailResponse} - 要素ステータス、押下判定
   */
  @Emit('confirmOpratipon')
  public confirmOpratipon(): void {
    if (hexabaseState.apiStatus !== networkErrorStatus) {
      let routeName = buttonConfig.portal.route;

      this.$store.commit('hexabase/setIsError', false);
      this.$store.commit('hexabase/setErrorMessage', '');

      if (hexabaseState.apiStatus === apiOutSessionStatus) {
        routeName = buttonConfig.signin.route;
        this.$store.commit('hexabase/clearHexabaseState');
        this.$store.commit('auth/clearToken');
        this.$store.commit('user/clearUserState');
        this.$store.commit('search/clearsearchCondition');
      }

      this.$router.push({ name: routeName });
    } else {
      this.$store.commit('hexabase/setIsError', false);
      this.$store.commit('hexabase/setErrorMessage', '');
    }
  }
}














import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class Typography extends Vue {
  // 表示するテキスト
  @Prop({ default: '' })
  private text!: string;
  // テキストのタイプ
  @Prop({ default: 'h1' })
  private type!: string;
  // テキストに付与する下余白
  @Prop({ default: '0' })
  private margin?: string;
}

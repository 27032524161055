import store, { SESSION_STORAGE_KEY } from '@/store';
import { Module, VuexModule, Mutation, getModule } from 'vuex-module-decorators';

export interface AppState {
  /** 定義 */
  options: { [proccess: string]: { [key: string]: any } };
}

/**
 * アプリ全般に関係する状態管理クラス
 * このクラスの状態はリロードしても状態が保持される
 */
@Module({
  name: 'app',
  namespaced: true,
  dynamic: true,
  store,
  // ↓リロードしても状態を保持する場合に、この1行で追加制御が必要
  preserveState: sessionStorage.getItem(SESSION_STORAGE_KEY) !== null
})
export class App extends VuexModule implements AppState {
  options: { [proccess: string]: { [key: string]: any } } = {};

  /**
   * optionsを設定する
   *
   * @param options
   */
  @Mutation
  setOptions(options: { [proccess: string]: { [key: string]: any } }) {
    this.options = options;
  }

  /**
   * optionsをリセットする
   */
  @Mutation
  clearAppState() {
    this.options = {};
  }

  /**
   * optionsを取得する
   *
   * @returns options
   */
  public get getOptions(): { [proccess: string]: { [key: string]: any } } {
    return this.options;
  }
}

export default getModule(App);

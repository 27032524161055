import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { AuthState } from './auth';
import { HexabaseState } from './hexabase';
import { UserState } from './user';
import { SearchState } from './search';
import { AppState } from './app';

Vue.use(Vuex);

export interface RootState {
  auth: AuthState;
  user: UserState;
  hexabase: HexabaseState;
  search: SearchState;
  app: AppState;
}

export const SESSION_STORAGE_KEY = 'VueBaseApp_session_key';

export default new Vuex.Store<RootState>({
  plugins: [
    createPersistedState({
      key: SESSION_STORAGE_KEY,
      paths: ['auth', 'user', 'hexabase', 'app'],
      storage: window.sessionStorage
    })
  ]
});
